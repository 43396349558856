import React from 'react';
import { createUseStyles } from 'react-jss';
import SectionHeading from '@src/components/SectionHeading';
import { Breakpoints } from '@ateams/components';
import user from './icon.svg';
import Modal from '@src/components/Modal';
import { Button } from '@a_team/ui-components';

interface ConfirmationModalProps {
  onConfirm(): void;
  onClose(): void;
  open: boolean;
}

const useStyles = createUseStyles({
  modal: {
    zIndex: 10003,
    textAlign: 'center',
    width: '680px !important',
    padding: '40px !important',
  },
  heading: {
    margin: '40px 0 0 0',
    fontSize: 20,
  },
  copy1: {
    marginTop: 8,
    padding: '0 75px',
    color: '#818388',
  },
  copy2: {
    marginTop: '24px',
    padding: '0 40px',
    color: '#818388',
  },
  actionWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '32px',
    paddingLeft: '12px',
    paddingRight: '12px',
    gap: '12px',
  },
  ctaButton: {
    marginRight: '24px',
    width: '160px',
    borderRadius: '8px',
  },
  [`@media (max-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '95% !important',
      padding: '20px !important',
    },
  },
});

export default function ConfirmSubmissionModal(props: ConfirmationModalProps) {
  const styles = useStyles();
  const { onClose, onConfirm, ...left } = props;

  return (
    <Modal onClose={props.onClose} {...left} className={styles.modal}>
      <div style={{ color: 'black' }}>
        <img src={user} style={{ width: 132 }} alt={'User'} />
        <SectionHeading className={styles.heading}>
          Are you ready to submit your profile for another review?
        </SectionHeading>
        <p className={styles.copy1}>
          Please confirm that you provided all the requested information. You’ll
          only have one opportunity to resubmit your profile.
        </p>
        <p className={styles.copy2}>
          If any information is missing, you must wait twelve months before
          requesting to join again.
        </p>
        <div className={styles.actionWrap}>
          <Button
            variant="secondary"
            onClick={onClose}
            className={styles.ctaButton}
          >
            No, go back
          </Button>

          <Button
            color="regular"
            onClick={onConfirm}
            className={styles.ctaButton}
          >
            Yes, submit
          </Button>
        </div>
      </div>
    </Modal>
  );
}
