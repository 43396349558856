import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import { History } from 'history';
import { Link, Redirect, RedirectProps } from 'react-router-dom';
import { useStores } from '@src/stores';
import {
  RootLocation,
  ResetPasswordLocation,
  SetupLocation,
} from '@src/locations';
import MainLayout from '@src/layouts/Main';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import {
  LoginData,
  LoginWithCredentials,
  LoginWithMethod,
  OAuthStartRequest,
} from '@ateams/api/dist/endpoints/Auth';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { createUseStyles } from 'react-jss';
import SectionHeading from '@src/components/SectionHeading';
import { Button as CallToActionButton } from '@ateams/components';
import { Divider } from '@ateams/components';
import RegistrationConnectButton, {
  ButtonIcon,
} from '@src/components/RegistrationConnectButton';
import { IconType } from '@src/components/RegistrationConnectButton/ButtonIcon';
import { useQuery } from '@src/hooks/useQuery';
import { OAuth } from '@src/helpers/oauth';
import qs from 'qs';

interface Props {
  history: History;
}

const useStyles = createUseStyles({
  signInCTA: {
    width: '100%',
  },
  form: {
    position: 'relative',
    backgroundColor: '#fff',
    padding: '24px',
    borderRadius: '1em',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.08)',
    width: '384px',
    margin: '10em auto 1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  passwordInput: {
    marginTop: 0,
    marginBottom: '24px',
  },
  forgotPassword: {
    marginBottom: '32px',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '24px',
    textAlign: 'right',
    color: '#FE630C',
    cursor: 'pointer',
    alignSelf: 'flex-end',
  },
});

const ONBOARDING_TOKEN_QUERY_PARAM = 'onboardingToken';
const REDIRECT_TO_PATH_QUERY_PARAM = 'redirectToPath';
const IDENTITY_TOKEN = 'identity-token';

export default function SignInView(props: Props): ReactElement {
  const { history } = props;
  const { auth } = useStores();

  const [data, setData] = useState<Partial<LoginData>>({});
  const [redirectTo, setRedirectTo] = useState<RedirectProps['to']>();
  const [loading, setLoading, error] = useLoadingState();
  const [oauth, setOAuth] = useState<OAuth>();

  const isValid = loading !== true && data.email && data.password;

  const styles = useStyles();
  const query = useQuery();
  const identityToken = query.get(IDENTITY_TOKEN);

  // if sign in page gets accessed and there's active token redirect user back to root
  useEffect(() => {
    if (auth.token) {
      history.replace('/');
    }
  }, [auth.token]);

  /**
   * The router defaults to this View when there's not auth token, including SSR. We can
   * leverage that to do auto authentication. in case of identity tokens,
   * we can simply reroute as soon as the token is redeemed.
   * the redeemIdentityToken will throw error on the FE telling that the token is not valid in case wrong token was pvoded
   */
  useEffect(() => {
    if (identityToken) {
      setLoading(
        auth.redeemIdentityToken(identityToken).then(() => {
          const redirect = query.get('redirect') ?? '/';
          history.push(redirect);
        }),
      );
    }
  }, [identityToken]);

  useEffect(() => {
    const onboardingTokenInQuery = query.get(ONBOARDING_TOKEN_QUERY_PARAM);
    const redirectToPathQuery = query.get(REDIRECT_TO_PATH_QUERY_PARAM);
    if (onboardingTokenInQuery) {
      auth
        .loginWith({
          method: LoginWithMethod.OnboardingToken,
          payload: onboardingTokenInQuery,
        })
        .then(() => {
          if (redirectToPathQuery) {
            query.delete(ONBOARDING_TOKEN_QUERY_PARAM);
            query.delete(REDIRECT_TO_PATH_QUERY_PARAM);
            setRedirectTo({
              pathname: redirectToPathQuery,
              search: query.toString(),
            });
          } else {
            history.push(getRedirectUrl());
          }
        })
        .catch(() => {
          query.delete(ONBOARDING_TOKEN_QUERY_PARAM);
          query.delete(REDIRECT_TO_PATH_QUERY_PARAM);
          history.replace({ search: query.toString() });
        });
    }
  }, []);

  useEffect(() => () => oauth?.close(), [oauth]);

  const getRedirectUrl = () => {
    if (auth.withOnboardingV2 && !auth.onboardingCompleted) {
      return SetupLocation;
    }

    const redirectUrl = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    }).redirect as string;

    return redirectUrl ? redirectUrl : RootLocation;
  };

  const loginWith = (payload: LoginWithCredentials): Promise<void> => {
    return auth.loginWith(payload).then(() => {
      history.push(getRedirectUrl());
    });
  };

  const handleInputChange =
    (name: keyof LoginData) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setData({ ...data, [name]: e.target.value });
    };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    setLoading(
      auth.login(data as LoginData).then((): void => {
        history.push(getRedirectUrl());
      }),
    );
  };

  const ignoreSubmit = (e: FormEvent): void => {
    e.preventDefault();
  };

  const handleOAuthStart = (provider: OAuthStartRequest['provider']): void => {
    const oauth = new OAuth();
    setOAuth(oauth);

    setLoading(
      auth.oauthStart({ provider }).then(
        (res) =>
          oauth.exchange(res.redirectURL).then((res) =>
            loginWith({
              method: LoginWithMethod.OAuth,
              payload: res.code,
            }),
          ),
        (err) => {
          oauth.close();
          throw err;
        },
      ),
    );
  };

  if (redirectTo) {
    return <Redirect to={redirectTo}></Redirect>;
  }

  return (
    <MainLayout title="Sign In" naked>
      <form
        onSubmit={isValid ? handleSubmit : ignoreSubmit}
        className={styles.form}
      >
        <SectionHeading
          isFirst
          style={{
            alignSelf: 'flex-start',
            marginBottom: 0,
            marginTop: '16px',
          }}
        >
          Sign In
        </SectionHeading>
        <OutlinedInput
          type="email"
          placeholder="Email..."
          name="email"
          onChange={handleInputChange('email')}
          value={data.email || ''}
          disabled={loading === true}
          required
          error={!!error}
        />
        <OutlinedInput
          type="password"
          className={styles.passwordInput}
          placeholder={'Password...'}
          name="password"
          onChange={handleInputChange('password')}
          value={data.password || ''}
          disabled={loading === true}
          required
          error={!!error}
        />
        <Link to={ResetPasswordLocation} className={styles.forgotPassword}>
          Forgot Password?
        </Link>
        <CallToActionButton
          type="submit"
          disabled={!isValid}
          className={styles.signInCTA}
        >
          Sign In
        </CallToActionButton>

        <div style={{ width: '100%' }}>
          <Divider style={{ marginTop: '32px', marginBottom: '32px' }} />
        </div>

        <RegistrationConnectButton
          type="button"
          style={{ width: '100%', margin: 0 }}
          disabled={loading === true}
          onClick={() => handleOAuthStart('google')}
        >
          <ButtonIcon type={IconType.Google} />
          Continue with Google
        </RegistrationConnectButton>

        <RegistrationConnectButton
          type="button"
          style={{ width: '100%', marginBottom: 0 }}
          disabled={loading === true}
          onClick={() => handleOAuthStart('github')}
        >
          <ButtonIcon type={IconType.GitHub} />
          Continue with Github
        </RegistrationConnectButton>

        <LoadingIndicator loading={loading} />
      </form>
    </MainLayout>
  );
}
