import React from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = (): URLSearchParams => {
  const { search } = useLocation();
  const decodedSearchParams = decodeURIComponent(search);

  return React.useMemo(
    () => new URLSearchParams(decodedSearchParams),
    [decodedSearchParams],
  );
};
