import React from 'react';
import { Button } from '@a_team/ui-components';
import { observer } from 'mobx-react';
import Step, { SetupProps, StepStatus } from '../common/Step';
import StepIcon, { StepIconType } from '../common/Step/StepIcon';
import { OnboardingStage } from '@a_team/models/dist/UserObject';
import { useRequestProfileReview } from '@src/rq/users';

interface ReviewProfileStepProps {
  onboardingStage: OnboardingStage;
}

const ReviewProfileStep = ({ onboardingStage }: ReviewProfileStepProps) => {
  const { mutate: requestProfileReview, isLoading } = useRequestProfileReview();

  const handleSubmitProfile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    requestProfileReview(undefined);
  };

  const INCOMPLETE_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.NUMBER} value="2" />,
    title: 'Submit your profile for review',
    description:
      'Our team will review your profile and ensure your background aligns with the needs of A.Team clients.',
    completionTime: 'Profile review can last up to 2 weeks',
    cta: (
      <Button size="md" onClick={handleSubmitProfile} disabled={isLoading}>
        {isLoading ? 'Submitting...' : 'Submit profile'}
      </Button>
    ),
    videoUrl: 'https://www.loom.com/share/ee754b940446404fb3e6a781a0824333',
    stepStatus:
      onboardingStage === OnboardingStage.PendingReviewRequest
        ? StepStatus.IN_PROGRESS
        : StepStatus.INCOMPLETE,
  };

  const IN_PROGRESS_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.IN_PROGRESS} />,
    title: 'Your profile is in review',
    description:
      'A member of our team will review your profile and reach out via email once complete.',
    completionTime: 'Estimated time to review: up to 2 weeks',
    cta: <div>In progress</div>,
    videoUrl: 'https://www.loom.com/share/ee754b940446404fb3e6a781a0824333',
    stepStatus: StepStatus.IN_PROGRESS,
  };

  const REJECTED_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.COMPLETED} />,
    title: 'Profile review complete',
    description:
      'A member of our team has reviewed your profile and requested more information.',
    cta: <div>Completed</div>,
    stepStatus: StepStatus.COMPLETED,
  };

  const COMPLETED_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.COMPLETED} />,
    title: 'Profile review complete',
    description:
      'A member of our team has reviewed your profile and invited you to an evaluation interview.',
    cta: <div>Completed</div>,
    stepStatus: StepStatus.COMPLETED,
  };

  const getPayload = () => {
    if (
      [
        OnboardingStage.IncompleteProfile,
        OnboardingStage.PendingReviewRequest,
      ].includes(onboardingStage)
    ) {
      return INCOMPLETE_DATA;
    }

    if (onboardingStage === OnboardingStage.ReviewRequested) {
      return IN_PROGRESS_DATA;
    }

    if (
      onboardingStage === OnboardingStage.PendingSecondReview ||
      onboardingStage === OnboardingStage.SecondReviewRequested
    ) {
      return REJECTED_DATA;
    }

    return COMPLETED_DATA;
  };

  const stepPayload = getPayload();

  return <Step {...stepPayload} />;
};

export default observer(ReviewProfileStep);
