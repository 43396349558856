import React from 'react';
import { Button } from '@a_team/ui-components';
import { observer } from 'mobx-react';
import Step, { SetupProps, StepStatus } from '../common/Step';
import StepIcon, { StepIconType } from '../common/Step/StepIcon';
import { OnboardingStage } from '@a_team/models/dist/UserObject';

interface EvaluationCallStepProps {
  onboardingStage: OnboardingStage;
  evaluationCallDate?: string;
  evaluationCallLink?: string;
}

const EvaluationCallStep = ({
  onboardingStage,
  evaluationCallDate,
  evaluationCallLink,
}: EvaluationCallStepProps) => {
  const stepNumber = [
    OnboardingStage.PendingSecondReview,
    OnboardingStage.SecondReviewRequested,
  ].includes(onboardingStage)
    ? '4'
    : '3';

  const INCOMPLETE_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.NUMBER} value={stepNumber} />,
    title: 'Showcase your expertise on an evaluation call',
    description:
      'Schedule a call with our team to share details about your skills and past experience on a short video call.',
    completionTime: 'Evaluation interview duration: 45 minutes',
    cta: <Button size="md">Schedule call</Button>,
    videoUrl: 'https://www.loom.com/share/ee754b940446404fb3e6a781a0824333',
    stepStatus:
      onboardingStage === OnboardingStage.PendingInterview
        ? StepStatus.IN_PROGRESS
        : StepStatus.INCOMPLETE,
  };

  const IN_PROGRESS_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.IN_PROGRESS} />,
    title: 'Your evaluation call has been scheduled',
    description: (
      <div>
        Join the call on {evaluationCallDate} using the link in your email or by
        clicking{' '}
        <a href={evaluationCallLink} target="_blank" rel="noreferrer">
          here
        </a>
        .
      </div>
    ),
    completionTime: 'Evaluation interview duration: 45 minutes',
    cta: <div>Scheduled</div>,
    videoUrl: 'https://www.loom.com/share/ee754b940446404fb3e6a781a0824333',
    stepStatus: StepStatus.IN_PROGRESS,
  };

  const COMPLETED_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.COMPLETED} />,
    title: 'Profile review complete',
    description:
      'A member of our team has reviewed your profile and invited you to an evaluation interview.',
    cta: <div>Completed</div>,
    stepStatus: StepStatus.COMPLETED,
  };

  const getPayload = () => {
    if (
      [
        OnboardingStage.IncompleteProfile,
        OnboardingStage.PendingReviewRequest,
        OnboardingStage.ReviewRequested,
        OnboardingStage.PendingSecondReview,
        OnboardingStage.SecondReviewRequested,
        OnboardingStage.PendingInterview,
      ].includes(onboardingStage)
    ) {
      return INCOMPLETE_DATA;
    }

    if (onboardingStage === OnboardingStage.InterviewScheduled) {
      return IN_PROGRESS_DATA;
    }

    return COMPLETED_DATA;
  };

  const stepPayload = getPayload();

  return <Step {...stepPayload} />;
};

export default observer(EvaluationCallStep);
