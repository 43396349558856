import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { Select } from '@a_team/ui-components';
import { OnboardingStage, UserStatus } from '@a_team/models/dist/UserObject';
import { Breakpoints } from '@ateams/components';
import { useStores } from '@src/stores';
import { RootLocation } from '@src/locations';
import MainLayout from '@src/layouts/Main';
import Header from './Header';
import CompleteProfileStep from './Steps/CompleteProfileStep';
import EvaluationCallStep from './Steps/EvaluationCallStep';
import JoinTheTeamStep from './Steps/JoinTheTeamStep';
import ReviewProfileStep from './Steps/ReviewProfileStep';
import ReviseProfileStep from './Steps/ReviseProfileStep';
import Welcome from './Welcome';
import ThankYouEvaluationCall from './ThankYouEvaluationCall';
import YouAreIn from './YouAreIn';
import LimitedMissionsModal from '@src/components/Modal/LimitedMissions';

const useStyles = createUseStyles<{ isLimited: boolean }>({
  container: {
    maxWidth: 1240,
    margin: '0 auto',
    padding: '16px 0',
  },
  stepsContainer: {
    margin: '40px auto 0',
    maxWidth: 680,
    display: 'flex',
    gap: 40,
    flexDirection: 'column',
  },
  select: {
    maxWidth: 400,
    margin: '40px auto 0',
  },
  content: {
    opacity: ({ isLimited }: { isLimited: boolean }) => (isLimited ? 0.04 : 1),
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    stepsContainer: {
      gap: 8,
      marginBottom: 40,
    },
  },
});

const Setup = () => {
  const { auth } = useStores();
  const isLimited = auth.userStatus === UserStatus.ActiveWaitlisted;
  const styles = useStyles({
    isLimited,
  });

  const [onboardingStage, setOnboardingStage] = useState<OnboardingStage>(
    auth.onboardingStage ?? OnboardingStage.IncompleteProfile,
  );

  useEffect(() => {
    setOnboardingStage(
      auth.onboardingStage ?? OnboardingStage.IncompleteProfile,
    );
  }, [auth.onboardingStage]);

  if (auth.withOnboardingV2 === false) {
    return <Redirect to={RootLocation} />;
  }

  const isYouAreIn = onboardingStage === OnboardingStage.Accepted;
  const isWelcome = onboardingStage === OnboardingStage.Completed;

  if (isYouAreIn) return <YouAreIn />;

  if (auth.limitedAccess) {
    return <ThankYouEvaluationCall />;
  } else if (isWelcome) {
    return <Welcome />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleStatusChange = (option: any) => {
    setOnboardingStage(option.value);
  };

  const evaluationCallDate = 'March 16, 2024 at 11:00 AM EST';
  const evaluationCallLink = 'https://www.google.com/';

  return (
    <MainLayout
      title="Setup Page"
      style={{ padding: '15px 24px' }}
      contentClassName={styles.content}
    >
      {isLimited && <LimitedMissionsModal open={isLimited} />}
      <div className={styles.container}>
        <Header
          title="Getting started as an A.Team Builder"
          subtitle="Complete the steps below to request to join A.Team, a network of world-class builders pursuing their craft with autonomy."
        />
        <div className={styles.select}>
          <p>Select onboarding stage (for testing only!)</p>
          <Select
            placeholder="Select onboarding stage"
            value={{ value: onboardingStage, label: onboardingStage }}
            options={Object.values(OnboardingStage).map((stage) => ({
              value: stage,
              label: stage,
            }))}
            hideSelectedOptions={false}
            onChange={handleStatusChange}
          />
        </div>
        <div className={styles.stepsContainer}>
          <CompleteProfileStep onboardingStage={onboardingStage} />
          <ReviewProfileStep onboardingStage={onboardingStage} />
          <ReviseProfileStep onboardingStage={onboardingStage} />
          <EvaluationCallStep
            onboardingStage={onboardingStage}
            evaluationCallDate={evaluationCallDate}
            evaluationCallLink={evaluationCallLink}
          />
          <JoinTheTeamStep />
        </div>
      </div>
    </MainLayout>
  );
};

export default observer(Setup);
